* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100vh;
  overflow: hidden;
  position: relative;
  /* border: 2px solid red; */
}

.second-app {
  width: 100%;
  height: 100%;
  height: calc(100vh - 90px);
  position: absolute;
  top: 90px;
  left: 0;
  padding: 20px;
  /* border: 2px solid yellow; */
  overflow: hidden;
}

@media (max-width: 767px) {
  .App {
    overflow-y: scroll;
  }
  .second-app {
    overflow-y: scroll;
  }
}

::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  height: 5px;
}
/* The part of the scrollbar you can drag */
::-webkit-scrollbar-thumb {
  background-color: #ffb07c; /* Color of the thumb */
  border-radius: 10px; /* Roundness of the thumb */
}

/* The track of the scrollbar */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the track */
  border-radius: 10px; /* Roundness of the track */
}

#validation-error {
  border: 1px solid red;
}

.all-modal-main-card {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.295);
}

.all-modal-inside-section {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  background-color: #fff;
}
.all-modal-closing-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.all-modal-form-button-card {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 2rem;
  width: 100%;
}

.all-modal-form-button-card > button {
  width: 120px;
  height: 35px;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 10px;
  font-size: 18px;
}
.all-modal-form-button-card > :nth-child(1) {
  background-color: var(--Grey_Color);
}

.all-modal-form-button-card > :nth-child(2) {
  background-color: var(--Secondary_Color);
}
