.home-main {
  width: 100%;
  overflow-y: scroll;
  height: 100vh;
}

.home-main > div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.home-first {
  width: 100%;
  display: flex;
  gap: 2rem;
  padding: 40px;
}

/* todo - list */

.home-first-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 30px;
  height: 370px;
}

.todo-first {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.todo-first > :nth-child(2) {
  cursor: pointer;
}
.todo-first > span {
  width: 30px;
  height: 30px;
  background-color: rgb(219, 235, 250);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  gap: 1rem;
}

.todo-first > :last-child {
  cursor: pointer;
}

.todo-second {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.todo-second-search {
  width: 45%;
  display: flex;
  border-radius: 10px;
  height: 40px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 0px 5px;
  align-items: center;
  gap: 0.4rem;
}

.todo-second-search > input {
  width: 90%;
  border: none;
  outline: none;
  height: 100%;
  font-size: 17px;
  color: grey;
}

.todo-tabs {
  width: 35%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid lightgrey;
  display: flex;
  justify-content: space-between;
}
.todo-tabs > span {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.todo-tabs > :nth-child(2) {
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
}

.todo-tabs > span > input {
  transform: scale(1.5);
  cursor: pointer;
}
.todo-third {
  width: 100%;
  height: 70%;
  /* border: 1px solid red; */
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-left: 10px;
}

.todo-third > div {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
}

.todo-third > div > input {
  transform: scale(1.5);
  cursor: pointer;
}

.todo-third > div > div {
  width: 96%;
  display: flex;
  flex-direction: column;
  /* gap: 0.rem; */
  /* background-color: #f3c233; */
  overflow: hidden;
}
.todo-third > div > div > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
}

@media (max-width: 767px) {
  .home-first {
    flex-direction: column;
    gap: 1rem;
  }
  .home-first-right {
    width: 100%;
    height: 420px;
  }
  .todo-first {
    width: 100%;
    padding: 10px;
  }
  .todo-first > h3 {
    font-size: 12px;
  }
  .todo-second {
    flex-direction: column;
    gap: 1rem;
  }
  .todo-second-search {
    width: 100%;
  }
  .todo-tabs {
    width: 100%;
  }
}
