.addp-student-main-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.add-student-main-card {
  width: 100%;
  height: calc(100% - 90px);
  display: flex;
  /* border: 2px solid blue; */
  gap: 1rem;
}

.add-question-edfgb {
  width: 70%;
  height: 100%;
}
.add-question-first-card {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  overflow-x: scroll;
  height: 100%;
}

.add-question-multi-btn-card {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 1rem;
}

.add-question-multi-btn-card > button {
  width: 120px;
  height: 35px;
  font-size: 17px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
}

.add-question-multi-btn-card > :nth-child(1) {
  border: 2px solid #ff6600;
  color: #ff6600;
  background-color: transparent;
}

.add-question-multi-btn-card > :nth-child(2) {
  background-color: #ff6600;
  color: #fff;
  border: none;
  outline: none;
}
.add-question-table-header {
  display: flex;
  width: fit-content;
  align-items: center;
  font-weight: bold;
  gap: 1rem;
  /* background-color: #f5f5f5; */
  white-space: nowrap;
  padding: 10px;
}

.add-question-table-header > :nth-child(1) {
  width: 80px;
}
.add-question-table-header > :nth-child(2) {
  width: 120px;
}
.add-question-table-header > :nth-child(3) {
  width: 200px;
}
.add-question-table-header > :nth-child(4) {
  width: 120px;
}
.add-question-table-header > :nth-child(5) {
  width: 120px;
}
.add-question-table-header > :nth-child(6) {
  width: 120px;
}
.add-question-table-header > :nth-child(7) {
  width: 120px;
}
.add-question-table-header > :nth-child(8) {
  width: 150px;
}
.add-question-table-header > :nth-child(9) {
  width: 180px;
}
.add-question-table-header > :nth-child(10) {
  width: 150px;
}

.add-question-table-body-card {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 95%;
  /* border: 2px solid red; */
  overflow-y: scroll;
}

.add-questiontable-single-card {
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 1rem;
  white-space: nowrap;
  padding: 10px;
  color: grey;
}

.add-questiontable-single-card > :nth-child(1) {
  width: 80px;
  display: flex;
  gap: 0.5rem;
}
.add-questiontable-single-card > :nth-child(1) > :nth-child(1) {
  cursor: pointer;
}

.add-questiontable-single-card > :nth-child(1) > :nth-child(2) {
  cursor: pointer;
}

.add-questiontable-single-card > :nth-child(2) {
  width: 120px;
}
.add-questiontable-single-card > :nth-child(3) {
  width: 200px;
  overflow-x: scroll;
}
.add-questiontable-single-card > :nth-child(4) {
  width: 120px;
  overflow-x: scroll;
}
.add-questiontable-single-card > :nth-child(5) {
  width: 120px;
  overflow-x: scroll;
}
.add-questiontable-single-card > :nth-child(6) {
  width: 120px;
  overflow-x: scroll;
}
.add-questiontable-single-card > :nth-child(7) {
  width: 120px;
  overflow-x: scroll;
}
.add-questiontable-single-card > :nth-child(8) {
  width: 150px;
  overflow-x: scroll;
}
.add-questiontable-single-card > :nth-child(9) {
  width: 180px;
}
.add-questiontable-single-card > :nth-child(10) {
  width: 150px;
}
.add-questiontable-single-card > :nth-child(11) {
  width: 150px;
}

.add-question-second-card {
  width: 30%;
  height: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.add-question-inner-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  padding: 20px 40px;
  overflow-y: scroll;
  /* border: 2px solid red; */
}

.add-question-input-card {
  width: 100%;
  height: 40px;
  display: flex;
  gap: 0.7rem;
  border: 1px solid lightgrey;
  align-items: center;
  /* padding: 10px; */
  border-radius: 10px;
  padding: 5px 10px;
}
.add-question-input-card > select {
  border: none;
  outline: none;
  width: 90%;
  height: 100%;
}

.validation-error {
  border: 2px solid red;
}

.add-question-input-card > input {
  width: 95%;

  height: 100%;
  border: none;
  outline: none;
  font-size: 16px;
}

.add-question-btn-card {
  height: 40px;
  width: 100%;
  cursor: pointer;
  background-color: #ff6600;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .addp-student-main-card {
    height: auto;
  }
  .add-student-main-card {
    flex-direction: column;
    height: auto;
    overflow-y: visible;
    gap: 2rem;
  }
  .add-question-edfgb {
    height: 90vh;
    /* background-color: blue; */
    width: 100%;
    overflow-x: scroll;
  }
  .add-question-second-card {
    width: 100%;
    height: auto; /* Let it adjust to the content */
    min-height: 300px;
  }
}
