.start-quize-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  padding: 20px;
  width: 100%;
  height: 100%;
}

.start-quize-left {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  padding: 30px;
  gap: 1rem;
  position: relative;

  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.start-quize-right {
  width: 40%;
  height: 100%;
  padding: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.after-quize-completed {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 768px) {
  .start-quize-main {
    flex-direction: column-reverse;
    gap: 2rem;
    height: fit-content;
  }
  .start-quize-left {
    width: 100%;
    height: 450px;
  }
  .start-quize-right {
    width: 100%;
    position: relative;
  }
}
