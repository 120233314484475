.quize-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.quize-inner-card {
  width: 100%;
  height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.quize-selected-card {
  width: 40%;
  padding: 60px 40px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 5px;
}

.quize-selected-card > select {
  width: 100%;
  height: 40px;
  border: 1px solid grey;
  border-radius: 4px;
  outline: none;
}

.quize-selected-card > button {
  width: 100%;
  height: 40px;
  background-color: #ff6600;
  color: #fff;
  font-size: 17px;
  border: none;
  border-radius: 4px;

  font-weight: 500;
  cursor: pointer;
}

.start-quize {
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .quize-selected-card {
    width: 95%;
  }
}
