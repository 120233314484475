.student-feedbacks {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.student-feedback-inner-card {
  width: 100%;
  height: calc(100vh - 90px);
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px;
  overflow-y: scroll;
}

.student-feedback-select-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.student-feedback-select-card > select {
  width: 350px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid lightgrey;
  outline: none;
  font-size: 16px;
  padding-left: 10px;
}
.student-feedback-select-card > div {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(252, 127, 127);
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.student-feedback-list-show-parent {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow-y: scroll;
}

.student-feedback-list-single-parents {
  display: flex;
  flex-direction: column;
  background-color: #ff660057;
  border-bottom: 1px solid #fff;
  padding: 10px;
}

.student-feedback-single-list-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
}

.student-feedback-single-list-inner-card {
  padding: 20px;
  /* background-color: red; */
  background-color: #fcb2812a;
  margin-left: 50px;
  width: 95%;
  height: 400px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.student-feedback-single-list-question-new-card {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.student-feedback-single-list-question-new-card > div {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin-left: 30px;
}

.student-feedback-single-list-question-new-card > h3 {
  color: grey;
}

.student-feedback-single-list-question-new-card > div > span {
  font-weight: 500;
  font-size: 18px;
  color: grey;
}
