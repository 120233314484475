canvas.react-pdf__page__canvas {
  margin-bottom: 50px;
}

.pdf-div {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.own-pdf-width {
  width: 95%;
  height: 95vh;
}
