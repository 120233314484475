.student-list-screen-first-second-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.student-list-screen-first-second-card > :nth-child(1) {
  width: 25%;
  font-size: 16px;
  font-weight: 600;
}

.student-list-screen-first-second-card > :nth-child(2) {
  font-size: 16px;
  font-weight: 600;
  width: 25%;
}
.student-list-screen-first-second-card > :nth-child(3) {
  font-size: 16px;
  font-weight: 600;
  width: 25%;
}
.student-list-screen-first-second-card > :nth-child(4) {
  font-size: 16px;
  font-weight: 600;
  width: 20%;
}
.student-list-screen-first-second-card > :nth-child(5) {
  font-size: 16px;
  font-weight: 600;
  width: 7%;
}

.student-list-screen-first-table-single-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.student-list-screen-first-table-single-card > :nth-child(1) {
  width: 25%;
}

.student-list-screen-first-table-single-card > :nth-child(2) {
  width: 25%;
}
.student-list-screen-first-table-single-card > :nth-child(3) {
  width: 25%;
}
.student-list-screen-first-table-single-card > :nth-child(4) {
  width: 20%;
}
.student-list-screen-first-table-single-card > :nth-child(5) {
  width: 6%;
  cursor: pointer;
}

.student-n-list-card {
  width: 30%;
  height: 100%;
}

@media (max-width: 768px) {
  .student-n-list-card {
    width: 100%;
  }
}
