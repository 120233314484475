.custome-table-main {
  width: 100%;
  padding: 20px 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-x: scroll;
}

.custom-t-f-c {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom-table-main {
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.custom-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(236, 233, 233);
  padding: 7px 2px;
  font-size: 17px;
  font-weight: 600;
  gap: 0.4rem;
}

.custom-table-main-body {
  width: 100%;
  height: 90%;
  /* border: 2px solid red; */
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.custom-table-single-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
  gap: 0.4rem;
  cursor: pointer;
}

.custom-table-single-card > div {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.custom-table-single-card > div,
.custom-table-single-card > span {
  overflow: hidden;
}

.easy-level {
  background-color: green;
  border-radius: 20px;
  color: white;
  padding-left: 10px;
  padding: 5px;
  font-size: 10px;
}

.medium-level {
  background-color: yellow;
  border-radius: 20px;
  color: black;
  padding-left: 10px;
  padding: 5px;
  font-size: 10px;
}

.hard-level {
  background-color: red;
  border-radius: 20px;
  color: white;
  padding-left: 10px;
  padding: 5px;
  font-size: 10px;
}

@media (max-width: 768px) {
  .custom-table-main {
    width: 650px;
  }
  .custome-table-main {
    height: 450px;
  }
}
