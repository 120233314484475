.material-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.add-material-main-card {
  width: 100%;
  height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.add-material-inner-card {
  width: 95%;
  height: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: auto;
}

.add-material-input-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}
.add-material-input-card > textarea {
  width: 50%;
  height: 100px;
  outline: none;
  max-width: 100%;
  border: 1px solid grey;
  padding: 10px 10px;
}

.add-materisl-single-input-card {
  width: 300px;

  height: 40px;
}

.add-materisl-single-input-card > select {
  border-radius: 4px;
  height: 100%;
  width: 100%;
  outline: none;
  border: 1px solid grey;
  padding: 0px 10px;
}

.add-materisl-single-input-card > input {
  border-radius: 4px;
  height: 100%;
  width: 100%;
  outline: none;
  border: 1px solid grey;
  padding: 0px 10px;
}

.material-add-form-btn-card {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.material-add-form-btn-card > button {
  width: 150px;
  height: 40px;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
}

.material-add-form-btn-card > :nth-child(1) {
  background-color: #ff6600;
  color: #fff;
}
