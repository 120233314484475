.resultp-screen-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.result-screen-container {
  width: 100%;
  height: calc(100% - 90px);
  display: flex;
  gap: 2rem;
  padding: 20px;
}

.result-screen-container-m {
  width: 70%;
  height: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.result-screen-first-card {
  width: 100%;
  padding: 20px;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* overflow-y: scroll; */
}

.studenr-n-table {
  width: 70%;
  height: 100%;
}

.result-screen-first-first-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0px 40px; */
}
.result-screen-first-first-card > div {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.result-screen-first-first-card > div > div {
  display: flex;
  align-items: center;
  border: 1px solid lightgrey;
  border-radius: 10px;
  height: 40px;
  width: 250px;
  display: flex;
  gap: 0.5rem;
  padding: 10px;
}

.result-screen-first-first-card > div > div > input {
  width: 95%;
  height: 100%;
  font-size: 16px;
  border: none;
  outline: none;
}

.result-screen-first-second-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.result-screen-first-second-card > :nth-child(1) {
  width: 15%;
  font-size: 16px;
  font-weight: 600;
}

.result-screen-first-second-card > :nth-child(2) {
  font-size: 16px;
  font-weight: 600;
  width: 20%;
}
.result-screen-first-second-card > :nth-child(3) {
  font-size: 16px;
  font-weight: 600;
  width: 10%;
}
.result-screen-first-second-card > :nth-child(4) {
  font-size: 16px;
  font-weight: 600;
  width: 10%;
}
.result-screen-first-second-card > :nth-child(5) {
  font-size: 16px;
  font-weight: 600;
  width: 15%;
}
.result-screen-first-second-card > :nth-child(6) {
  font-size: 16px;
  font-weight: 600;
  width: 15%;
}
.result-screen-first-second-card > :nth-child(7) {
  font-size: 16px;
  font-weight: 600;
  width: 10%;
}

.result-screen-first-second-card > :nth-child(8) {
  font-size: 16px;
  font-weight: 600;
  width: 5%;
}

.result-screen-first-table-body-card {
  width: 100%;
  height: 80%;
  /* border: 2px solid red; */
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.result-screen-first-table-single-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.result-screen-first-table-single-card > :nth-child(1) {
  width: 15%;
}

.result-screen-first-table-single-card > :nth-child(2) {
  width: 20%;
}
.result-screen-first-table-single-card > :nth-child(3) {
  width: 10%;
}
.result-screen-first-table-single-card > :nth-child(4) {
  width: 10%;
}
.result-screen-first-table-single-card > :nth-child(5) {
  width: 15%;
}
.result-screen-first-table-single-card > :nth-child(6) {
  width: 15%;
}
.result-screen-first-table-single-card > :nth-child(7) {
  width: 10%;
}

.result-screen-first-table-single-card > :nth-child(8) {
  width: 4.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.result-screen-second-card {
  width: 30%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: scroll;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-image: radial-gradient(#e78544, #f38943);
}

.result-screen-second-first-card {
  height: 30%;
  /* border: 1px solid lightgrey; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 0.5rem;
  overflow: hidden;
  background-color: #fff;
}

.result-screen-second-second-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #fff;
  height: 70%;
  padding: 20px;
  border-radius: 10px;
}
.result-screen-second-second-first-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.result-screen-second-second-second-card {
  width: 100%;
  height: 89%;
  /* border: 2px solid red; */
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.result-screen-second-second-second-card > div {
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.pass-failed-card-parent {
  position: relative;
}

.pass-failed-card {
  position: absolute;
  top: 2px;
  right: 0px;
  padding: 0px 5px;
  color: white;
  border-radius: 99999px;
}

.pass {
  background-color: green; /* Green for "P" */
}

.fail {
  background-color: red; /* Red for "F" */
}

.result-screen-second-second-second-card > div > img {
  width: 50px;
  height: 50px;
}

@media (max-width: 768px) {
  .resultp-screen-container {
    height: auto; /* Allow the container to adapt based on its content */
  }
  .result-screen-container {
    flex-direction: column;
    height: auto;
    overflow-y: visible;
    /* background-color: red; */
  }

  .result-screen-container-m {
    height: 90vh;
    /* background-color: blue; */
    width: 100%;
    overflow-x: scroll;
  }

  .result-screen-first-card {
    width: 1000px;
    height: auto; /* Let it adjust to the content */
    min-height: 100vh; /* Ensure minimum height is set */
  }

  .result-screen-second-card {
    width: 100%;
    height: auto; /* Let it adjust to the content */
    min-height: 300px; /* Ensure minimum height is set */
  }

  .result-screen-second-second-second-card {
    height: auto;
    overflow-y: auto; /* Enable scrolling if content exceeds height */
  }
}
