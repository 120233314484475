.all-materials {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.all-materials-main-card {
  width: 100%;
  height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.all-materials-inner-card {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 30px;
  border-radius: 10px;
}

.all-materials-select-card {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.all-materials-select-card > select {
  border-radius: 4px;
  height: 40px;
  width: 350px;
  outline: none;
  border: 1px solid grey;
  padding: 0px 10px;
}

.all-materials-inner-show-card {
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  /* background-color: red; */
}

.single-material-card {
  width: 250px;
  height: 250px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #1c87c91a;
}

.single-material-card > h4 {
  text-align: center;
}

.single-material-card > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
